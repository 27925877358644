import React from 'react';

import Layout from '../../components/layout';
import SEO from '../../components/seo';

import { Container, Row, Col } from 'reactstrap';

import Link from '../../net/Link';
import { purge_future_content } from '../../utils/contentbuilder';
import { graphql } from 'gatsby';

/**
 * This is a hacked helper page to enable to behaviour practiced by the
 * old website: Whenever you went to /lets-be-fwends-/latest , you would
 * be directed to the newest edition of Let's be Fwends.
 * 
 * This was possible because everything was done on one component; Here, we
 * get a finished list of pages created at build time, each campaign getting
 * its virtual page, which is loaded into the campaign template.
 * There is no "/latest" campaign, so we need this class to get the actual path.
 * Then fromm here, we try a purely client-side js-based redirect by re-writing
 * the window.location.href
 * 
 * Also, we display a text explaining what is happening in case the re-write does
 * not work.
 */
class Latest extends React.Component {

  componentDidMount() {
    let nodes = purge_future_content(this.props.data.allMarkdownRemark.edges);
    window.location.href = nodes[0].node.frontmatter.path;
  }

  render() {

    let nodes = purge_future_content(this.props.data.allMarkdownRemark.edges);

    let title = nodes[0].node.frontmatter.title;
    let link = nodes[0].node.frontmatter.path;

    return (
      <Layout>
        <SEO title="Latest Issue" />
        <div>
          <Container fluid className="w-80 content-page block">
            <Row>
              <Col>
                <h1>Redirecting you</h1>
                <p>I'm trying to redirect you to the latest Issue of Let's be Fwends,
    called <em>"{title}"</em>
                </p>
                <p>If that doesn't work, <Link to={link}>please click here to go there manually</Link>.</p>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    )
  }

}

export default Latest;

export const pageQuery = graphql`
    query {
allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, filter: {frontmatter: {path: {regex: "/lets-be-fwends/"}, date: {}, lang: {}}}) {
  edges {
    node {
      id
      excerpt(pruneLength: 250)
      html
      frontmatter {
        date
        path
        featureImage
        title
        abstract
      }
    }
  }
}}`